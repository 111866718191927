<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5>{{ title }} MFA User</h5>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="onSubmit" autocomplete="off">
              <div class="ibox-content">
                <div class="row">
                  <div class="form-group col-md-5">
                    <ValidationProvider
                      name="User Id"
                      :rules="'required|alpha_dash'"
                      v-slot="{ errors, classes }"
                    >
                      <label>User Id *</label>
                      <input
                        type="text"
                        placeholder="User Id"
                        v-model="user.userID"
                        class="form-control"
                        id="userId"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-5">
                    <label>User Key *</label>
                    <ValidationProvider
                      name="User Key"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        placeholder="User Key"
                        v-model="user.userKey"
                        id="userKey"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group col-md-5">
                    <label>Incorrect Attempts *</label>
                    <ValidationProvider
                      name="Incorrect Attempts"
                      :rules="{ required: true }"
                      v-slot="{ errors, classes }"
                    >
                      <input
                        type="text"
                        placeholder="Incorrect Attempts"
                        v-model="user.incorrAttempts"
                        id="incorrAttempts"
                        class="form-control"
                        :class="classes"
                      />
                      <span class="error">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <!-- <div class="form-group col-md-2">
                    <label>Enabled *</label>
                    <div class="switch">
                      <div class="onoffswitch">
                        <input
                          type="checkbox"
                          class="onoffswitch-checkbox form-control"
                          v-model="user.isMFAenabled"
                          id="isMFAenabled"
                        />
                        <label class="onoffswitch-label" for="isMFAenabled">
                          <span class="onoffswitch-inner"></span>
                          <span class="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div> -->
                  <div class="form-group col-md-2">
                    <label>Active *</label>
                    <div class="switch">
                      <div class="onoffswitch">
                        <input
                          type="checkbox"
                          class="onoffswitch-checkbox form-control"
                          v-model="user.isActive"
                          id="isActive"
                        />
                        <label class="onoffswitch-label" for="isActive">
                          <span class="onoffswitch-inner"></span>
                          <span class="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-4 col-sm-offset-2">
                    <button class="btn btn-white btn-sm" type="button">
                      Clear
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      class="btn btn-primary btn-sm ladda-button"
                      data-style="zoom-in"
                      type="submit"
                      :disabled="invalid"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import DataTableService from "../plugin/DataTableService";
import Utils from "../plugin/Utils";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      user: {
        id: 0,
        incorrAttempts: 0,
        isActive: 0,
        isMFAenabled: 1,
        userID: "",
        userKey: "",
      },
      title: Constant.data.ADD_TITLE,
      url: Constant.url.MFA_USER_ADD_URL,
      dataTablesRole: "dataTables-MFA",
    };
  },
  created: function () {
    this.loadMfaData();
  },
  mounted: function () {
    var self = this;
  },
  methods: {
    onSubmit: function () {
      const self = this;
      this.user.isActive = this.user.isActive ? "1" : "0";
      this.user.isMFAenabled = this.user.isMFAenabled ? "1" : "0";
      AxiosService.post(this.url, this.user).then((result) => {
        if (
          Utils.hasValue(result, Constant.data.RESPONSE_CODE) &&
          result.responseCode == Constant.data.SUCCESS
        ) {
          self.$router.replace('/mfauser');
        }
      });
    },
    loadMfaData: async function () {
      var mfaId = this.$route.query.id;
      const self = this;
      if (mfaId != undefined) {
        AxiosService.get(Constant.url.MFA_USER_GET_URL + mfaId).then(
          (result) => {
            this.user = result;
          }
        );
        this.title = Constant.data.UPDATE_TITLE;
        this.url = Constant.url.MFA_USER_UPDATE_URL;
      }
    },
  },
  watch: {
    groupData() {
      const self = this;
      Utils.initalizedSelect2("#groupId", this.groupData);
    },
    serviceProvider() {
      const self = this;
      Utils.initalizedSelect2("#serviceProvider", this.serviceProvider);
    },
  },
};
</script>
<style scoped>
.panel-default {
  border-color: #8d909285 !important;
}
</style>
